<template>
  <div class="content">
    <div class="container">
      <div class="head">
        <div class="head_item">
          <div class="head_left">
            <div class="head_title">总内容</div>
            <div class="head_sum">{{ informationNUm.totalNum }}</div>
          </div>

          <div class="head_right">
            <div class="head_img sum_distribution">
              <img src="../../../static/images/Group 2727.png" alt="" />
            </div>
            <div class="head_sum">
              <span class="head_h2Title">今日新增</span>
              <span class="head_newAdd">{{ informationNUm.todayNum }}</span>
              <i class="el-icon-top"></i>
            </div>
          </div>
        </div>
        <div class="head_item">
          <div class="head_left">
            <div class="head_title">知识内容</div>
            <div class="head_sum">{{ informationNUm.totalKnowledgeNum }}</div>
          </div>

          <div class="head_right">
            <div class="head_img activity_distribution">
              <img src="../../../static/images/Group 2728.png" alt="" />
            </div>
            <div class="head_sum">
              <span class="head_h2Title">今日新增</span>
              <span class="head_newAdd">{{ informationNUm.knowledgeNum }}</span>
              <i class="el-icon-top"></i>
            </div>
          </div>
        </div>
        <div class="head_item">
          <div class="head_left">
            <div class="head_title">情报内容</div>
            <div class="head_sum">{{ informationNUm.totalIntelligenceNum }}</div>
          </div>

          <div class="head_right">
            <div class="head_img demand_distribution">
              <img src="../../../static/images/Group 2729.png" alt="" />
            </div>
            <div class="head_sum">
              <span class="head_h2Title">今日新增</span>
              <span class="head_newAdd">{{ informationNUm.intelligenceNum }}</span>
              <i class="el-icon-top"></i>
            </div>
          </div>
        </div>
        <div class="head_item">
          <div class="head_left">
            <div class="head_title">集市内容</div>
            <div class="head_sum">{{ informationNUm.totalMarketNum }}</div>
          </div>

          <div class="head_right">
            <div class="head_img report_distribution">
              <img src="../../../static/images/Group 2730.png" alt="" />
            </div>
            <div class="head_sum">
              <span class="head_h2Title">今日新增</span>
              <span class="head_newAdd">{{ informationNUm.marketNum }}</span>
              <i class="el-icon-top"></i>
            </div>
          </div>
        </div>
      </div>
      <el-card>
        <div class="context">
          <el-form ref="form" :model="queryInfo" label-width="100px">
            <el-form-item label="资讯类型：">
              <el-radio-group v-model="queryInfo.informationType" @change="changeSearchangeSearch">
                <el-radio-button
                  :label="item.id"
                  v-for="(item, index) in InformationTypes"
                  :key="index"
                  >{{ item.value }}</el-radio-button
                >
              </el-radio-group>
            </el-form-item>
            <el-form-item label="资讯来源：">
              <el-radio-group
                v-model="queryInfo.informationChannel"
                @change="changeSearchangeSearch"
              >
                <el-radio-button
                  :label="item.id"
                  v-for="(item, index) in InformationSource"
                  :key="index"
                  >{{ item.value }}</el-radio-button
                >
              </el-radio-group>
            </el-form-item>
            <!-- 新增 -->
            <div class="category">
              <!--            <el-form-item label="资讯类别：">-->
              <!--              <el-radio-group v-model="queryInfo.informationChannel" @change="search">-->
              <!--                <el-radio-button-->
              <!--                  :label="item.id"-->
              <!--                  v-for="(item, index) in InformationCategory"-->
              <!--                  :key="index"-->
              <!--                  >{{ item.value }}</el-radio-button-->
              <!--                >-->
              <!--              </el-radio-group>-->
              <!--            </el-form-item>-->
              <!--            <div class="address">-->
              <!--              <el-form-item label="资讯类别：">-->
              <!--                <el-radio-group v-model="queryInfo.informationChannel" @change="search">-->
              <!--                  <el-radio-button-->
              <!--                    :label="item.id"-->
              <!--                    v-for="(item, index) in InformationCategory"-->
              <!--                    :key="index"-->
              <!--                    >{{ item.value }}</el-radio-button-->
              <!--                  >-->
              <!--                </el-radio-group>-->
              <!--              </el-form-item>-->
              <!--            </div>-->
            </div>
            <!-- /新增 -->
          </el-form>

          <!-- 新增--搜索 -->
          <div class="add_search">
            <div class="left">
              <div class="add" @click="clickAdd">导入</div>

              <el-button style="margin-right: 5px" @click="SetGeneral">设置城市智参</el-button>
              <!-- <el-button style="margin-right: 5px" @click="pushPlan">数据上传</el-button> -->

              <el-upload
                v-if="queryInfo.informationType == 10"
                action
                :http-request="upload"
                :show-file-list="false"
                :on-success="handleSuccess"
                :before-upload="beforeUpload"
              >
                <el-button style="margin: 0px 10px" plain>数据上传</el-button>
              </el-upload>
              <el-button style="margin-right: 5px" @click="updateOnShelf">批量发布</el-button>
              <el-button style="margin-right: 5px" @click="updateOffShelf">批量下架</el-button>
              <el-button style="margin-right: 5px" @click="checkClicks">设置通用版资讯</el-button>

              <div class="ckeckbox_text">
                <el-checkbox
                  :indeterminate="isIndeterminate"
                  v-model="checkAll"
                  @change="handleCheckAllChange"
                  >全选</el-checkbox
                >
              </div>
            </div>
            <div class="right">
              <el-input
                v-model="searchInput"
                placeholder="请输入资讯名称,智选企业简称"
                clearable
              ></el-input>
              <div class="add" @click="searchInputClick">搜索</div>
            </div>
          </div>

          <!-- 表格 -->
        </div>
      </el-card>
      <div
        class="tab_txt"
        v-loading="loading"
        element-loading-text="加载中..."
        element-loading-spinner="el-icon-loading"
      >
        <div class="order_up">
          <div style="padding-right: 50px">
            <span>当前条件下共查出{{ total }}篇资讯</span>
          </div>
          <div class="order_flex">
            按查看人数
            <div class="main">
              <span
                class="arrUp"
                @click="clickUp1"
                :style="{ 'border-bottom-color': color1 }"
              ></span>
              <span
                class="arrDown"
                @click="clickDown2"
                :style="{ 'border-top-color': color2 }"
              ></span>
            </div>
          </div>
          <div style="display: flex; align-items: center; margin-right: 30px">
            按分享人数
            <div class="main">
              <span
                class="arrUp"
                @click="clickUp3"
                :style="{ 'border-bottom-color': color3 }"
              ></span>
              <span
                class="arrDown"
                @click="clickDown4"
                :style="{ 'border-top-color': color4 }"
              ></span>
            </div>
          </div>
          <div style="display: flex; align-items: center; margin-right: 30px">
            按对接人数
            <div class="main">
              <span
                class="arrUp"
                @click="clickUp5"
                :style="{ 'border-bottom-color': color5 }"
              ></span>
              <span
                class="arrDown"
                @click="clickDown6"
                :style="{ 'border-top-color': color6 }"
              ></span>
            </div>
          </div>
        </div>

        <div class="storeCenter" v-for="(item, index) in listData" :key="index">
          <div class="item_checkbox">
              <el-checkbox
                @change="latechange($event, item)"
                v-model="item.checked1"
                label=""
              ></el-checkbox>
            </div>
          <div class="storeCenter_item" >
            <!-- <div class="item_checkbox">
              <el-checkbox
                @change="latechange($event, item)"
                v-model="item.checked1"
                label=""
              ></el-checkbox>
            </div> -->
            <div class="storeCenter_item_top">
              <div class="storeCenter_item_top_left">
                <div class="banner_box">
                  <div class="activity_state" v-if="item.generalVersion == 0">
                    <span>通用版</span>
                  </div>
                  <img :src="item.coverImages" alt="" />
                </div>
                <div class="storeCenter_item_top_left_flex">
                  <div style="display: flex; align-items: center; padding-bottom: 10px">
                    <h5
                      style="
                        display: inline-block;
                        margin: 0;
                        font-size: 18px;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        display: -webkit-box;
                        -webkit-line-clamp: 1;
                        width: 200px;
                        -webkit-box-orient: vertical;
                      "
                    >
                      {{ item.informationName }}
                    </h5>

                    <div class="mark_box">
                      <div v-if="item.informationChannel == 1">
                        <img src="../../assets/image/Group 3298.png" alt="" /><span>平台内容</span>
                      </div>
                      <div v-if="item.informationChannel == 2">
                        <img src="../../assets/image/展厅-5 3.png" alt="" /><span>渠道内容</span>
                      </div>
                      <div v-if="item.informationChannel == 3">
                        <img src="../../assets/image/展厅-5 3.png" alt="" /><span>系统导入</span>
                      </div>
                    </div>
                  </div>
                  <div class="address">
                    <span v-if="item.informationType == 1"> 内容类型: 应用场景 </span>
                    <span v-if="item.informationType == 2"> 内容类型: 数智技术 </span>
                    <span v-if="item.informationType == 3"> 内容类型: 行业动态 </span>
                    <span v-if="item.informationType == 4"> 内容类型: 数智案例 </span>
                    <span v-if="item.informationType == 5"> 内容类型: 数智产品 </span>
                    <span v-if="item.informationType == 6"> 内容类型: 服务商 </span>
                    <span v-if="item.informationType == 7"> 内容类型: 政策解读 </span>
                    <span v-if="item.informationType == 8"> 内容类型: 组织人才 </span>
                  </div>
                  <div class="address" style="display: flex; align-items: center">
                    <span>发布机构：</span>
                    <img
                      width="15"
                      height="15"
                      style="border-radius: 15px"
                      :src="item.issueLogo"
                      alt=""
                    />
                    <span style="margin-left: 5px"> {{ item.issueName }} </span>
                  </div>
                  <div class="storeCenter_item_top_left_flex_tag" v-if="item.labels">
                    <span style="display: flex" v-if="item.labels">
                      <span v-for="(o, index) in item.labels" :key="index">
                        <span v-if="index < 2" class="office">{{ o }}</span>
                      </span>
                    </span>
                    <el-tooltip class="item" effect="light" placement="right">
                      <div slot="content" style="width: 400px; display: flex; flex-wrap: wrap">
                        <span
                          style="
                            border: 1px solid #559eff;
                            color: #4e93fb;
                            padding: 1px 6px;
                            display: block;
                            font-size: 14px;
                            font-weight: 400;
                            border-radius: 4px;
                            margin: 5px;
                          "
                          v-for="(o, index) in item.labels"
                          :key="index"
                          >{{ o }}</span
                        >
                      </div>
                      <el-button type="text" style="height: 20px">
                        <span class="text_buttom">{{ item.labels.length }}+</span>
                      </el-button>
                    </el-tooltip>
                  </div>
                </div>
              </div>
              <div class="storeCenter_item_top_center">
                <div class="count_box" @click="topush(item)">
                  <div>{{ item.pushCompanyNum }}</div>
                  <div>推送企业数</div>
                </div>
                <div class="count_box" @click="clickScanNum(item)">
                  <div>{{ item.viewCount || 0 }}</div>
                  <div>查看用户数</div>
                </div>
                <div class="count_box" @click="clickShareNum(item)">
                  <div>{{ item.shareCount || 0 }}</div>
                  <div>分享用户数</div>
                </div>
                <div class="count_box" @click="clickLinkNumNum(item)">
                  <div>{{ item.linkNum || 0 }}</div>
                  <div>对接数</div>
                </div>
              </div>

              <div class="storeCenter_item_top_right">
                <div class="operation_area">
                  <el-button type="primary" @click="clickMatching(item, 2)">算法配置</el-button>
                  <el-button type="info" plain @click="clickMatching(item, 1)">手动配置</el-button>

                  <el-button type="info" @click="clcikgensing(item)" plain>配置智参 </el-button>
                  <el-button type="info" @click="clciklabel(item)" plain
                    >标签
                    <span v-if="item.labels"> ({{ item.labels.length }})</span>
                  </el-button>
                  <span
                    v-if="item.offShelf == 1 || !item.offShelf"
                    class="edit"
                    @click="OnShelf(item)"
                    >发布</span
                  >
                  <span v-if="item.offShelf == 0" class="frozen" @click="OffShelf(item)">下架</span>

                  <span class="edit" @click="clcikRedact(item)">编辑</span>
                  <!-- <span class="frozen" @click="delcompany(item)">冻结</span> -->
                  <span
                    v-if="item.isDelete == 0 || item.isDelete == null"
                    class="frozen"
                    @click="delcompany(item)"
                    >冻结</span
                  >
                  <span v-if="item.isDelete == 1" class="frozen" @click="cancelcompany(item)"
                    >激活</span
                  >
                </div>
                <div class="details">操作人：{{ item.userName }}</div>
                <div class="details">最近操作时间：{{ item.time }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="new_page" v-if="listData.length">
      <el-pagination
        :current-page="queryInfo.pageNum"
        :page-sizes="[10, 30, 50]"
        :page-size="queryInfo.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      />
    </div>
    <el-dialog
      title="批量配置智参中心"
      :visible.sync="dialogVisible"
      width="627px"
      class="account_dialog"
    >
      <el-form label-width="100px" :model="pageParam" ref="form">
        <el-form-item label="所在地区:" prop="">
          <el-select v-model="pageParam.province" placeholder="请选择省份" @change="citychange">
            <el-option :value="''" label="全部"></el-option>
            <el-option
              v-for="item in operatingList"
              :key="item.id"
              :label="item.name"
              :value="item.name"
            >
            </el-option>
          </el-select>
          <div style="display: inline-block; width: 10px"></div>
          <el-select v-model="pageParam.city" placeholder="请选择地区" @change="domGinsengPage">
            <el-option :value="''" label="全部"></el-option>
            <el-option
              v-for="item in operatingLists"
              :key="item.id"
              :label="item.name"
              :value="item.name"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="中心名称：" prop="link">
          <div class="disinput">
            <el-input
              placeholder="请输入智参中心名称"
              v-model="pageParam.queryConditions"
              class="input-with-select"
            >
              <el-button
                style="
                  border: 1px solid #448aff;
                  background-color: #448aff;
                  color: #fff;
                  border-radius: 0;
                "
                class="seach"
                slot="append"
                @click="domGinsengPage()"
                >搜索</el-button
              >
            </el-input>
          </div>
        </el-form-item>
      </el-form>
      <el-table
        v-loading="loadingisen"
        element-loading-text="加载中..."
        element-loading-spinner="el-icon-loading"
        ref="multipleTable"
        :data="listgisen"
        stripe
        width="100%"
        class="in_table"
        @selection-change="handleSelectionChange"
        row-key="id"
        :header-cell-style="{ background: '#F8F9FB', color: '#595959' }"
      >
        <el-table-column type="selection" align="center" :reserve-selection="true" />
        <el-table-column label="智参中心名称" prop="zcName" align="center"></el-table-column>
        <el-table-column label="所在地区" prop="" align="center">
          <template v-slot="{ row }">
            <span>{{ row.province }}-{{ row.city }}</span>
          </template>
        </el-table-column>
      </el-table>
      <div class="new_page">
        <el-pagination
          :current-page="pageParam.pageNum"
          :page-sizes="[10, 30, 50]"
          :page-size="pageParam.pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="totaldins"
          @size-change="handleSize"
          @current-change="handleCurrent"
        />
      </div>
      <div style="text-align: center; margin-top: 40px">
        <el-button type="primary" @click="checkClick()">确 定</el-button>
        <el-button @click="dialogVisible = false">取 消</el-button>
      </div>
    </el-dialog>
    <el-dialog
      title="配置智参中心"
      :visible.sync="dialogVisibles"
      width="700px"
      class="account_dialog"
    >
      <el-form label-width="100px" :model="pageParam" ref="form">
        <el-form-item label="所在地区:" prop="">
          <el-select v-model="pageParam.province" placeholder="请选择省份" @change="citychange">
            <el-option :value="''" label="全部"></el-option>
            <el-option
              v-for="item in operatingList"
              :key="item.id"
              :label="item.name"
              :value="item.name"
            >
            </el-option>
          </el-select>
          <div style="display: inline-block; width: 10px"></div>
          <el-select v-model="pageParam.city" placeholder="请选择地区" @change="wisdomGinsengPage">
            <el-option :value="''" label="全部"></el-option>
            <el-option
              v-for="item in operatingLists"
              :key="item.id"
              :label="item.name"
              :value="item.name"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="中心名称：" prop="link">
          <div class="disinput">
            <el-input
              placeholder="请输入智参中心名称"
              v-model="pageParam.queryConditions"
              class="input-with-select"
            >
              <el-button
                style="
                  border: 1px solid #448aff;
                  background-color: #448aff;
                  color: #fff;
                  border-radius: 0;
                "
                class="seach"
                slot="append"
                @click="wisdomGinsengPage()"
                >搜索</el-button
              >
            </el-input>
          </div>
        </el-form-item>
        <div class="add_search">
          <div class="left">
            <div class="add" @click="setShelf(1)">批量上架</div>
            <el-button style="margin-right: 5px" @click="setShelf(2)">批量下架</el-button>
          </div>
        </div>
      </el-form>
      <el-table
        ref="multipleTables"
        v-loading="loadingisen"
        element-loading-text="加载中..."
        element-loading-spinner="el-icon-loading"
        :data="listgisen"
        stripe
        width="100%"
        class="in_table"
        @selection-change="handleSelectionChange"
        :header-cell-style="{ background: '#F8F9FB', color: '#595959' }"
      >
        <el-table-column type="selection" align="center" :reserve-selection="true" />
        <el-table-column label="智参中心名称" prop="zcName" align="center"></el-table-column>
        <el-table-column label="所在地区" prop="" align="center">
          <template v-slot="{ row }">
            <span>{{ row.province }}-{{ row.city }}</span>
          </template>
        </el-table-column>
        <el-table-column label="操作人" prop="userName" align="center"></el-table-column>

        <el-table-column label="操作时间" prop="createTime" align="center"></el-table-column>

        <el-table-column label="操作" prop="" align="center">
          <template v-slot="{ row }">
            <span v-if="row.isShelf == 1" @click="setShelf(1, row, 1)" class="support">上架</span>
            <span v-if="row.isShelf == 0" @click="setShelf(2, row, 1)" class="office">下架</span>
          </template>
        </el-table-column>
      </el-table>
      <div class="new_page">
        <el-pagination
          :current-page="pageParam.pageNum"
          :page-sizes="[10, 30, 50]"
          :page-size="pageParam.pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="totaldins"
          @size-change="handleSizeChanges"
          @current-change="handleCurrentChanges"
        />
      </div>
      <div style="text-align: center; margin-top: 40px">
        <el-button type="primary" @click="dialogVisibles = false">确 定</el-button>
        <el-button @click="dialogVisibles = false">取 消</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
const defaultQueryInfo = Object.freeze({
  informationType: '',
  orderByParam: 'n.create_time DESC',
  pageNum: 1,
  pageSize: 10,
  queryConditions: '',
  formalUser: '',
  informationChannel: '',
  generalVersion: null
})
import { pagination } from '@/mixins/pagination'
import {
  informationNum,
  informationPage,
  deleteById,
  updateGeneralVersion,
  updateGeneralVersions,
  updateOffShelf,
  updateOnShelf,
  wisdomGinsengPage,
  wisdomGinsengOffSelf,
  wisdomGinsengOnSelf,
  domGinsengPage,
  freezeInformation,
  cancelFreezeInformation
} from '../../api/content.js'
import { getByFatherIdt, getProvince, pushPlanInformation } from '@/api/demand'
export default {
  mixins: [pagination],
  data() {
    //这里存放数据
    return {
      // 遮罩层
      loading: true,
      //配置智参
      loadingisen: false,
      checkAll: false,
      isIndeterminate: false,
      InformationSource: [
        {
          value: '全部',
          id: ''
        },
        {
          value: '平台自增',
          id: 1
        },
        {
          value: '系统导入',
          id: 3
        },
        {
          value: '渠道新增',
          id: 2
        }
      ],
      InformationTypes: [
        {
          value: '全部',
          id: ''
        },
        {
          value: '应用场景',
          id: 1
        },
        {
          value: '数智技术',
          id: 2
        },

        {
          value: '行业动态',
          id: 3
        },
        {
          value: '案例视频',
          id: 4
        },

        {
          value: '数智产品',
          id: 5
        },
        {
          value: '数智服务商',
          id: 6
        },
        {
          value: '政策解读',
          id: 7
        },
        {
          value: '组织人才',
          id: 8
        },
        {
          value: '数智案例',
          id: 10
        },
        {
          value: '场景知识',
          id: 11
        },
        {
          value: '电信专区',
          id: 12
        }
      ],
      // 资讯类别
      InformationCategory: [
        {
          value: '全部',
          id: ''
        },
        {
          value: '通用资讯',
          id: 1
        },
        {
          value: '地域资讯',
          id: 2
        },
        {
          value: '其他资讯',
          id: 3
        }
      ],
      uploading: false,
      color1: '',
      color2: '#C6C6C6',
      color3: '',
      color4: '#C6C6C6',
      color5: '',
      color6: '#C6C6C6',
      // 只看服务商上传的咨询 的多选框
      checked: false,
      // 搜索文本框内容
      searchInput: '',
      // 表格数据
      listData: [],
      // 资讯类名控制
      typesClass: 999,
      // 接口得到的资讯条数数据
      informationNUm: {},
      //  表格需要的数据
      queryInfo: { ...defaultQueryInfo },
      //
      imageShow: -1,
      enterpriseIntroShow: -1,
      // 移除内容池所需要的id
      PoolId: null,
      // 移除成功的字段
      removeSucceed: 0,
      aaa: 0,
      ids: [],
      idsList: [],
      dialogVisible: false,
      dialogVisibles: false,
      //控制是否显示更多标签
      isShowTags: false,
      tagIndex: '',
      //批量设置智参中心筛选条件
      pageParam: {
        city: null,
        id: null,
        orderByParam: null,
        pageNum: 1,
        pageSize: 10,
        province: null,
        queryConditions: null
      }, //配置智参中心
      listgisen: [],
      totaldins: 0,
      idgisen: [],
      operatingList: [], //城市
      operatingLists: [] //省份
    }
  },
  created() {
    this.getInformationNUm()
    // this.search()
    if (this.$route.query.changeNum) {
      this.queryInfo.pageNum = Number(localStorage.getItem('paginationCurrentPage'))
      this.search()
    } else {
      this.queryInfo.pageNum = 1
      this.search()
    }
  },
  methods: {
    //进去城市
    async getByFatherIdt(id) {
      const res = await getByFatherIdt({ provinceCode: id })
      if (res.data.resultCode == 200) {
        this.operatingLists = res.data.data
      }
    },
    //城市
    async incity() {
      const res = await getProvince()
      if (res.data.resultCode == 200) {
        this.operatingList = res.data.data
      }
    },
    //资讯配置智参中心数据一览
    async wisdomGinsengPage() {
      this.loadingisen = true
      const { data: res } = await wisdomGinsengPage(this.pageParam)
      if (res.resultCode == 200) {
        this.listgisen = res.data.list
        this.totaldins = res.data.total
        this.loadingisen = false
      }
    },
    //分页查询智参中心
    async domGinsengPage() {
      delete this.pageParam.id
      this.loadingisen = true
      const { data: res } = await domGinsengPage(this.pageParam)
      if (res.resultCode == 200) {
        this.listgisen = res.data.list
        this.totaldins = res.data.total
        this.loadingisen = false
      }
    },
    // 分页资讯配置智参中心数据一览
    handleSizeChanges(val) {
      this.pageParam.pageSize = val
      this.wisdomGinsengPage()
    },
    // 分页资讯配置智参中心数据一览
    handleCurrentChanges(val) {
      this.pageParam.pageNum = val
      this.wisdomGinsengPage()
    },
    // 分页资讯配置智参中心数据一览
    handleSize(val) {
      this.pageParam.pageSize = val
      this.domGinsengPage()
    },
    // 分页资讯配置智参中心数据一览
    handleCurrent(val) {
      this.pageParam.pageNum = val
      this.domGinsengPage()
    },
    // 上下架
    setShelf(type, row, index) {
      let informationId = ''
      if (index === 1) {
        this.idgisen = [row.schemaName]
        informationId = row.informationId
      } else {
        informationId = this.pageParam.id
      }
      if (this.idgisen.length > 0) {
        const query = {
          informationId,
          schemaNames: this.idgisen
        }
        const bol = type === 1 ? true : false
        const fn = bol ? wisdomGinsengOnSelf : wisdomGinsengOffSelf
        const txt = bol ? '上架' : '下架'
        const that = this
        this.$alert('是否确认' + txt + '？')
          .then(function () {
            return fn(query)
          })
          .then(() => {
            that.wisdomGinsengPage()
            that.idgisen = []
            this.$refs.multipleTables.clearSelection()
            this.$message.success(txt + '成功')
          })
          .catch(() => {})
      } else {
        this.$message.warning('请选择智参中心')
      }
    },
    citychange(item) {
      this.operatingList.forEach((o) => {
        if (this.pageParam.province == o.name) {
          this.pageParam.city = ''
          if (item == '全部') {
            this.operatingLists = this.operatingList.map((el) => el.cityResults).flat(Infinity)
            console.log(this.operatingLists, 'this.operatingLists ')
          } else {
            this.operatingLists = this.operatingList.find((el) => el.name == item).cityResults
          }
        }
      })
      this.search()
    },
    // 多选框选中数据
    handleSelectionChange(selection) {
      this.idgisen = selection.map((item) => item.schemaName)
    },
    // 资讯类型的点击方法
    clickType(item) {
      this.typesClass = item.id
      if (item.id == 999) {
        this.query.informationType = null
        this.search()
      } else {
        this.query.informationType = item.id
        this.search()
      }

      // console.log(item.value)
    },
    // 多选框选中数据
    latechange(bol, row) {
      const index = this.ids.indexOf(row.id)
      if (index == -1) {
        this.ids.push(row.id)
        this.idsList.push(row)
      } else {
        this.ids.splice(index, 1)
        this.idsList.splice(index, 1)
      }
    },
    //控制标签展示
    showTags(i) {
      this.tagIndex = i
      this.isShowTags = true
    },
    hideTags() {
      this.isShowTags = false
    },
  
    //排序按查看人数
    clickUp1() {
      this.queryInfo.orderByParam = 'viewCount ASC'
      this.search()
      this.color1 = '#0099ff'
      this.color2 = ''
    },
    clickDown2() {
      this.queryInfo.orderByParam = 'viewCount DESC'
      this.search()
      this.color1 = ''
      this.color2 = '#0099ff'
    },
    //排序按分享人数
    clickUp3() {
      this.queryInfo.orderByParam = 'shareCount ASC'
      this.search()
      this.color3 = '#0099ff'
      this.color4 = ''
    },
    clickDown4() {
      this.queryInfo.orderByParam = 'shareCount DESC'
      this.search()
      this.color3 = ''
      this.color4 = '#0099ff'
    },
    //排序按对接人数
    clickUp5() {
      this.queryInfo.orderByParam = 'linkNum ASC'
      this.search()

      this.color5 = '#0099ff'
      this.color6 = ''
    },
    clickDown6() {
      this.queryInfo.orderByParam = 'linkNum DESC'
      this.search()

      this.color5 = ''
      this.color6 = '#0099ff'
    },
    //配置智参
    clcikgensing(row) {
      this.pageParam.id = row.id
      this.dialogVisibles = true
      this.wisdomGinsengPage()
      this.incity()
    },
    SetGeneral() {
      if (this.ids.length > 0) {
        this.dialogVisible = true
        this.domGinsengPage()
        this.incity()
      } else {
        this.$message.warning('请选择资讯')
      }
    },
    //批量设置资讯类型---通用版 (未登录用户可看)
    checkClicks() {
      if (this.ids.length > 0) {
        const that = this
        this.$alert('是否确认设置成通用资讯？')
          .then(function () {
            that.loading = true
            return updateGeneralVersions(that.ids)
          })
          .then(() => {
            this.search()
            this.ids = []
            this.isIndeterminate = false
            this.checkAll = false

            this.$message.success('设置成功')
          })
          .catch(() => {})
      } else {
        this.$message.warning('请选择资讯')
      }
    },
    // 上传企业Excel
    async upload(file) {
      const form = new FormData()
      form.append('file', file.file)
      const { data: res } = await pushPlanInformation(form)

      if (res.resultCode == 200) {
        this.$message.success('数据上传成功!')
        this.search()
      } else {
        this.$message.warning('数据上传失败！')
      }
    },
    // 上传完成前
    beforeUpload(file) {
      // this.uploading = true //上传时显示
      // this.form.size = file.size
      // var fileSize = file.size / 1024 / 1024 < 100
      this.$message('正在上传中，请稍等')
    },
    //上传成功回调
    handleSuccess(res, file) {
      // console.log(res)
      //后台上传地址
      // this.uploading = false //无论是否成功懒加载都隐藏
      // if (res.resultCode == 0) {
      //   this.$message.success('上传成功')
      //   this.form.path = res.resultData
      //   console.log(res.resultData)
      // } else {
      //   this.$message.error('上传失败，请重新上传')
      // }
    },
    //批量设置资讯
    checkClick() {
      if (this.ids.length > 0) {
        const query = {
          ids: this.ids,
          schemaNames: this.idgisen
        }
        const that = this
        this.$alert('是否确认设置成城市智参资讯？')
          .then(function () {
            return updateGeneralVersion(query)
          })
          .then(() => {
            this.search()
            this.ids = []
            this.isIndeterminate = false
            this.checkAll = false
            this.dialogVisible = false
            this.idgisen = []
            this.$refs.multipleTable.clearSelection()
            this.$message.success('设置成功')
          })
          .catch(() => {})
      } else {
        this.$message.warning('请选择资讯')
      }
    },
    //批量下架
    updateOffShelf() {
      if (this.ids.length > 0) {
        const that = this
        this.$alert('是否确认批量下架资讯？')
          .then(function () {
            return updateOffShelf(that.ids)
          })
          .then(() => {
            this.search()
            this.ids = []
            this.isIndeterminate = false
            this.checkAll = false
            this.$message.success('下架成功')
          })
          .catch(() => {})
      } else {
        this.$message.warning('请选择资讯')
      }
    },
    //批量上架
    updateOnShelf() {
      if (this.ids.length > 0) {
        const that = this
        this.$alert('是否确认批量发布资讯？')
          .then(function () {
            return updateOnShelf(that.ids)
          })
          .then(() => {
            this.ids = []
            this.isIndeterminate = false
            this.checkAll = false
            this.$message.success('发布成功')
            this.search()
          })
          .catch(() => {})
      } else {
        this.$message.warning('请选择资讯')
      }
    },
    //批量下架
    OffShelf(row) {
      const ids = []
      ids.push(row.id)

      this.$alert('是否确认下架资讯？')
        .then(function () {
          return updateOffShelf(ids)
        })
        .then(() => {
          this.ids = []
          this.isIndeterminate = false
          this.checkAll = false
          this.search()
          this.$message.success('下架成功')
        })
        .catch(() => {})
    },
    //批量上架
    OnShelf(row) {
      const ids = []
      ids.push(row.id)

      this.$alert('是否确认上架资讯？')
        .then(function () {
          return updateOnShelf(ids)
        })
        .then(() => {
          this.ids = []
          this.isIndeterminate = false
          this.checkAll = false
          this.search()

          this.$message.success('上架成功')
        })
        .catch(() => {})
    },

    handleCheckAllChange(val) {
      if (val) {
        this.listData.forEach((item) => {
          this.ids.push(item.id)
          this.idsList.push(item)
          this.$set(item, 'checked1', true)
        })
      } else {
        this.listData.forEach((item) => {
          this.ids = []
          this.idsList = []
          this.$set(item, 'checked1', false)
        })
      }
      this.isIndeterminate = val
    },
    // 只看服务商上传的咨询 的点击事件
    clcikChecked() {
      if (this.checked == true) {
        this.queryInfo.generalVersion = '0'
        this.search()
      } else if (this.checked == false) {
        this.queryInfo.generalVersion = null
        this.search()
      }
      // console.log(this.checked)
    },
    // 搜索文本框的点击事件
    searchInputClick() {
      this.queryInfo.pageNum = 1
      this.queryInfo.queryConditions = this.searchInput
      this.search()
      // console.log(this.searchInput)
    },
    // 表格排序
    changeTableSort(column) {},
    // 点击新增
    clickAdd() {
      let routeData = this.$router.resolve({
        path: '/content/add',
        query: {
          headTabActive: 0
        }
      })
      window.open(routeData.href, '_blank')
      // console.log('新')
    },
    // 点击编辑
    clcikRedact(row) {
      let routeData = this.$router.resolve({
        path: '/content/redact',
        query: {
          id: row.id,
          headTabActive: 0,
          type: true
        }
      })
      window.open(routeData.href, '_blank')
      // console.log(row, '编辑')
    },
    //冻结资讯
    delcompany(row) {
      const params = {
        id: row.id
      }

      this.$alert('您是否确认冻结？')
        .then(function () {
          return freezeInformation(params)
        })
        .then(() => {
          this.search()
          this.$message.success('冻结成功')
        })
        .catch(() => {})
    },
    //激活资讯
    cancelcompany(row) {
      const params = {
        id: row.id
      }

      this.$alert('您是否确认激活？')
        .then(function () {
          return cancelFreezeInformation(params)
        })
        .then((res) => {
          if (res.data.resultCode == 200) {
            this.search()
            this.$message.success('激活成功')
          } else {
            this.$message.warning(res.data.message)
          }
        })
        .catch(() => {})
    },
    //资讯标签
    clciklabel(row) {
      this.$router.push({
        path: '/content/redact',
        query: {
          id: row.id,
          headTabActive: 1
        }
      })
    },
    // 点击删除
    async clickDel(row) {
      const { data: res } = await deleteById({
        id: row.id
      })
      if (res.resultCode == 200) {
        this.search()
        this.$message.success('删除成功')
      }
    },

    // 点击匹配
    clickMatching(row, index) {
      this.$router.push({
        path: '/content/matching',
        query: {
          id: row.id,
          type: index
        }
      })
      // console.log(row, '匹配')
    },
    //已推送企业数
    topush(row) {
      this.$router.push({
        path: '/content/PushEnterprise',
        query: {
          id: row.id
        }
      })
    },
    // 头部资讯条数 数据
    async getInformationNUm() {
      const { data: res } = await informationNum()
      // console.log(res)
      if (res.resultCode == 200) {
        this.informationNUm = res.data
      }
    },
    // 表格数据
    async search() {
      this.loading = true
      const { data: res } = await informationPage(this.queryInfo)
      if (res.resultCode == 200) {
        this.listData = res.data.list
        this.listData.forEach((item) => {
          this.$set(item, 'checked1', false)
        })
        this.total = res.data.total
        this.removeSucceed = 1
        this.loading = false
      }
    },
    //  查看人数的跳转
    clickScanNum(row) {
      this.$router.push({
        path: '/content/check',
        query: {
          id: row.id,
          sharedock: 2
        }
      })
    },

    //  分享人数的跳转
    clickShareNum(row) {
      this.$router.push({
        path: '/content/share',
        query: {
          id: row.id,
          sharedock: 1
        }
      })
    },

    // 想联系人数的跳转
    clickLinkNumNum(row) {
      this.$router.push({
        path: '/content/particulars',
        query: {
          id: row.id
        }
      })
    },
    // 分页
    handleSizeChange(val) {
      this.queryInfo.pageSize = val
      this.isIndeterminate = false
      this.checkAll = false
      this.ids = []
      this.idsList = []
      this.search()
    },
    // 分页
    handleCurrentChange(val) {
      this.queryInfo.pageNum = val
      localStorage.setItem('paginationCurrentPage', val)
      this.isIndeterminate = false
      this.checkAll = false
      this.ids = []
      this.idsList = []
      this.search()
    },
    // 搜索
    changeSearchangeSearch() {
      this.queryInfo.pageNum = 1
      this.search()
    }
  }
}
</script>
<style scoped lang="less">
::v-deep .el-checkbox__inner::after {
  box-sizing: content-box;
  content: '';
  border: 1px solid #fff;
  border-left: 0;
  border-top: 0;
  height: 7px;
  left: 5px;
  position: absolute;
  top: 2px;
  transform: rotate(45deg) scaleY(0);
  width: 4px;
  transition: transform 0.15s ease-in 50ms;
  transform-origin: center;
}
::v-deep .el-tag--mini {
  height: 20px;
  padding: 0 5px;
  line-height: 19px;
  margin-right: 10px;
}
::v-deep .el-checkbox__inner {
  display: inline-block;
  position: relative;
  border: 1px solid #dcdfe6;
  border-radius: 2px;
  box-sizing: border-box;
  width: 18px;
  height: 18px;
  background-color: #fff;
  z-index: 1;
  transition: border-color 0.25s cubic-bezier(0.71, -0.46, 0.29, 1.46),
    background-color 0.25s cubic-bezier(0.71, -0.46, 0.29, 1.46);
}
::v-deep .storeCenter_item_top_right .el-button {
  width: 100px;
}
.content {
 
  .new_page {
    width: 100%;
    text-align: center;
   
  }
  .head {
    width: 100%;
    height: 165px;
    display: flex;
    justify-content: space-between;
    margin-bottom: 16px;
    .head_item {
      width: 400px;
      height: 165px;
      background-color: #fff;
      border-radius: 12px;
      display: flex;
      .head_left {
        width: 50%;
        height: 100%;
        color: #333;
        margin-left: 32px;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        .head_title {
          font-size: 32px;
          color: #333;
        }
        .head_sum {
          font-size: 26px;
          color: #333;
        }
      }

      .head_right {
        box-sizing: border-box;
        width: 50%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: flex-end;
        padding-right: 42px;
        .head_img {
          width: 89px;
          height: 89px;
          border-radius: 50%;
          img {
            width: 100%;
            height: 100%;
          }
        }
        .head_sum {
          // display: flex;

          .head_h2Title {
            font-size: 14px;
            color: #ff6c66;
          }
          .head_newAdd {
            margin: 0 6px;
            font-size: 26px;
            color: #ff6c66;
          }
        }
      }
      .head_right i {
        font-size: 21px;
        font-weight: 700;
        color: #ff6c66;
      }
    }
  }
  .input-with {
    width: 100%;
    overflow: hidden;
    display: flex;
    justify-content: space-between;
    padding: 0px 10px;

    .input-with-select {
      width: 400px;
    }

    .seach {
      background-color: #448aff;
      color: #fff;
      border-radius: 0;
      border: 1px solid #448aff;
    }
  }
  .add_search {
    height: 64px;
    display: flex;
    justify-content: space-between;
    padding-bottom: 16px;
    .left {
      display: flex;
      align-items: flex-end;
      .add {
        width: 85px;
        height: 40px;
        border-radius: 4px;
        cursor: pointer;
        background-color: #448aff;
        color: #fff;
        font-size: 14px;
        font-weight: 400;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 13px;
      }
      .ckeckbox_text {
        /deep/.el-checkbox__inner {
          width: 18px;
          height: 18px;
        }
        /deep/.el-checkbox__label {
          color: #333;
        }
        /deep/.el-checkbox__inner::after {
          height: 10px;
          left: 6px;
        }
      }
    }

    .right {
      display: flex;
      align-items: flex-end;
      .add {
        transform: translateX(-2px);
        width: 74px;
        height: 40px;
        cursor: pointer;
        background-color: #448aff;
        color: #fff;
        font-size: 14px;
        font-weight: 400;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 33px;
      }
      /deep/.el-input {
        width: 429px;
      }
    }
  }

  /deep/ .el-radio-button__inner {
    border: none;
    margin-right: 12px;
    border-radius: 4px;
  }
  .in_table {
    width: 100%;
    margin-top: 20px;

    .img_url {
      width: 42px;
      height: 42px;
      border-radius: 4px;
    }
  }
}
.tab_txt {
  background: #f6f7fb;

  margin-top: 20px;
  padding: 0px;
  padding-bottom: 0px;
  .order_up {
    display: flex;
    align-items: center;
    padding-bottom: 10px;
    .order_flex {
      display: flex;
      align-items: center;
      margin-right: 30px;
    }
    .main {
      display: flex;
      flex-direction: column;
    }

    .arrUp {
      width: 0;
      height: 0;
      border-right: 8px solid transparent;
      border-left: 8px solid transparent;
      border-bottom: 8px solid #c6c6c6;
      cursor: pointer;
    }

    .arrDown {
      width: 0;
      height: 0;
      margin-top: 2px;
      border-right: 8px solid transparent;
      border-left: 8px solid transparent;
      border-top: 8px solid #c6c6c6;
      cursor: pointer;
    }
  }
  .storeCenter{
    width: 100%;
    overflow: hidden;
    margin-bottom: 15px;
    display: flex;
    align-items: center;
    border-radius: 4px;
    box-sizing: border-box;
    position: relative;
    .item_checkbox {
      line-height: 36px;
      margin: 0px 10px;
    }
  }
 
  .storeCenter_item {
    flex: 1;
    display: flex;
    align-items: center;
    border-radius: 4px;
    box-sizing: border-box;
    position: relative;

   
    .Relevancy {
      position: absolute;
      width: 83px;
      height: 25px;
      top: 15px;
      left: 9px;
      background: #ff7d18;
      border-radius: 10px 15px 15px 0px;
      font-size: 12px;
      text-align: center;
      line-height: 25px;
      color: #ffffff;
    }
    .Relevancyblue {
      position: absolute;
      width: 83px;
      height: 25px;
      top: 15px;
      left: 9px;
      background: #146aff;
      border-radius: 10px 15px 15px 0px;
      font-size: 12px;
      text-align: center;
      line-height: 25px;
      color: #ffffff;
    }
    .storeCenter_item_top {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      box-sizing: border-box;
      padding-bottom: 14px;
      position: relative;
      background: #ffffff;
      padding: 16px 20px 10px 10px;
      .storeCenter_item_top_left {
        display: flex;
        flex-direction: row;
        width: 38%;

        .office {
          color: #4e93fb;

          cursor: pointer;
        }

        .support {
          cursor: pointer;
          color: #fd523f;
        }
        .banner_box {
          position: relative;
          width: 175.55px;
          height: 115.73px;
          padding-right: 10px;
          img {
            width: 170.55px;
            height: 117.73px;
            border-radius: 4px;
            margin-right: 18px;
          }

          .activity_state {
            position: absolute;
            width: 51px;
            height: 23px;
            background: #448aff;
            border-radius: 2px;
            color: #fff;
            font-size: 14px;
            text-align: center;
            line-height: 23px;
            top: 5px;
            left: 5px;
          }
        }

        .storeCenter_item_top_left_flex {
          display: flex;
          flex-direction: column;
          justify-content: center;

          .generalVersion {
            background: #448aff;
            border-radius: 2px;
            padding: 5px;
            font-family: 'PingFang SC';
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            /* identical to box height */
            margin: 0 10px;
            color: #ffffff;
          }
          .mark_box {
            display: flex;
            img {
              width: 14px;
              // height: 14px;
              margin-right: 6px;
              color: #6e6e6e;
            }
            & > div {
              margin-left: 10px;
            }
          }
          .img_commer {
            display: flex;
            border: 1px solid #146aff;
            border-radius: 39px;
            .name {
              font-size: 12px;
              line-height: 20px;
              color: #146aff;
            }
            .color_img {
              background: #146aff;
              height: 20px;
              width: 25px;
              border-radius: 20px;
              position: relative;
              left: -10px;
              img {
                width: 13px;
                height: 13px;
                position: relative;
                left: 5px;
                top: 2px;
              }
            }
          }
          .img_commers {
            display: flex;
            border: 1px solid #ff9c00;
            border-radius: 39px;
            margin: 0px 10px;
            .name {
              font-size: 12px;
              line-height: 20px;
              color: #ff9c00;
            }
            .color_img {
              background: #ff9c00;
              height: 20px;
              width: 25px;
              border-radius: 20px;
              position: relative;
              left: -10px;
              img {
                width: 13px;
                height: 13px;
                position: relative;
                left: 5px;
                top: 2px;
              }
            }
          }

          h4 {
            color: #1f2d3d;
            font-size: 26px;
            font-weight: 500;
            margin: 0;
          }

          .storeCenter_item_top_left_flex_tag {
            padding-top: 10px;
            display: flex;
            flex-direction: row;
            align-content: center;

            margin-bottom: 1px;
            .text_buttom {
              border: 1px solid #559eff;
              color: #4e93fb;
              padding: 1px 6px;
              display: block;
              font-size: 14px;
              font-weight: 400;
              border-radius: 4px;
              margin: 5px;
              position: relative;
              top: -16px;
              left: -5px;
            }
            .row {
              display: flex;
              align-items: center;
            }
            .show_tags {
              position: relative;
              width: 50px;
            }
            .tags_box {
              display: flex;
              flex-flow: row wrap;
              justify-content: left;
              width: 400px;
              padding-left: 10px;
              background-color: rgba(241, 241, 241, 0.6);
              box-shadow: 0 2px 4px rgba(241, 241, 241, 0.25);
              position: absolute;
              left: 100%;
              top: 100%;
              z-index: 9999999999 !important;
              .el-tag {
                height: 18px;
                margin: 10px 10px 0 0;
                padding: 0 5px;
                line-height: 16px;
                font-size: 12px;
              }
            }
            span {
              box-sizing: border-box;
              display: block;
              font-size: 14px;
              font-weight: 400;
              border-radius: 4px;
            }

            .office {
              border: 1px solid #559eff;
              color: #4e93fb;
              padding: 1px 6px;
              margin-right: 10px;
            }

            .support {
              border: 1px solid #fd523f;
              color: #fd523f;
              margin-left: 12px;
            }
          }

          .address {
            font-size: 16px;
            padding: 5px 0px;
          }
        }
      }
      .storeCenter_item_top_center {
        display: flex;
        width: 25%;
        .count_box {
          height: 80px;
          width: 120px;
          align-items: center;
          display: flex;
          flex-direction: column;
          justify-content: flex-end;
          cursor: pointer;
          &:nth-of-type(1) {
            border-right: 1px solid #e8e8e8;
          }
          & > div:nth-of-type(1) {
            font-family: 'PingFang SC';
            font-style: normal;
            font-weight: 500;
            font-size: 20px;

            color: #078bff;
          }
          & > div:nth-of-type(2) {
            margin-top: 20px;
            font-family: 'PingFang SC';
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            color: #333333;
          }
        }
      }
      .storeCenter_item_top_right {
        align-items: stretch;
        height: 100%;
        position: relative;

        .operation_area {
          margin-top: 30px;
          .buttom_right {
            height: 36px;
            line-height: 1px;
          }
          .edit {
            padding-left: 10px;
            color: #4e93fb;
            cursor: pointer;
          }
          .frozen {
            padding-left: 10px;
            color: #fd5469;
            cursor: pointer;
          }
        }
        .details {
          text-align: right;
          font-family: 'PingFang SC';
          font-style: normal;
          font-weight: 400;
          font-size: 10px;
          color: #999999;
          margin-top: 5px;
        }
      }
    }
  }
}

::v-deep .disinput .el-input__inner {
  -webkit-appearance: none;
  background-color: #fff;
  background-image: none;
  border-radius: 4px;
  border: 1px solid #dcdfe6;
  box-sizing: border-box;
  color: #606266;
  display: inline-block;
  font-size: inherit;
  height: 40px;
  line-height: 40px;
  outline: 0;
  padding: 0 15px;
  transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
  width: 382px;
}
::v-deep .disinput .el-input-group {
  line-height: normal;
  display: inline-table;
  width: 381px;
  border-collapse: separate;
  border-spacing: 0;
}

// .address {

// }
</style>
